.banner {
    background-color: $monthly-primary;
    color: $white;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;
    margin-top: -5px;

    &-img {
        height: 400px;
        width: 80%;
        background-image: url('../assets/img/mini-phone-screen.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-color: $monthly-primary;
        background-position: center center;
    }

    &-text {
        padding-top: $mobile-vertical-padding;

        &-heading {
            line-height: 1.0;
        }

        &-small {
            font-size: 1.0rem;
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }

    .partner-icon-group {
        margin-top: 30px;

        .partner-icon {
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 30px;

            &-mono {
                width: 84px;
                height: 12px;
            }

            &-smile {
                width: 142px;
                height: 18px;
            }

            &-dot {
                width: 114px;
                height: 56px;
            }
        }
    }
}

.benefit-content {
    display: block;
    z-index: 5;
}

.benefits-animation {
    overflow-x: hidden;
    display: flex;
    flex: flex-wrap;
    width: 100vw!important;
}

.benefits-container {
    width: 100vw!important;
    height: 799px;
    z-index: 5;
    position: relative;
}

.benefit-bg-img {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 799px;
}

.benefit-card {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    padding-top: 86px;
    position: relative;
}

.benefit-slider-one {
    h2{
        color: $white;
    }
    h4{
        color: $monthly-primary;
    }
    h6{
        color: $white;
    }
    h5{
        color: $white;
    }
    p{
        color: $white;
    }
}

.benefit-slider-two {
    h2{
        color: $white;
    }
    h4{
        color: $monthly-primary;
    }
    h6{
        color: $green;
    }
    h5{
        color: $white;
    }
    p{
        color: $white;
    }
}

.benefit-slider-three {
    h2{
        color: $monthly-primary;
    }
    h4{
        color: black;
    }
    h6{
        color: $monthly-primary;
    }
    h5{
        color: black;
    }
    p{
        color: black;
    }
}

.benefit-slider-four {
    h2{
        color: $white;
    }
    h4{
        color: $white;
    }
    h6{
        color: $white;
    }
    h5{
        color: $white;
    }
    p{
        color: $white;
    }
}

.benefit-slider-five {
    h2{
        color: $monthly-primary;
    }
    h4{
        color: black
    }
    h6{
        color: $monthly-primary;
    }
    h5{
        color: black
    }
    p{
        color: black;
    }
}

.left-benefits-card {
    width: 525px;
    text-align: left;

    h2 {
        font-weight: 600;
        font-size: 65px;
        margin-bottom: 100px;

    }

    h4 {
        font-size: 50px;
        font-weight: 500;
        
    }

    h6 {
        font-size: 40px;
        font-weight: 500;
        width: 341px;
    }

    h5 {
        
        letter-spacing: 2px;
        font-weight: 500;
        font-size: 15px;
        text-align: left;
        margin-bottom: 15px;
    }

    p {
        font-size: 20px;
        width: 382px;
        margin-top: 23px;
    }
}

.barcode-img {
    text-align: left;
}

.right-benefits-card {
    width: 436px;

    img {
        width: 100%;
    }
}

.banner-two-container {
    height: 619px;
    width: 100%;
    z-index: 5;
    position: relative;
}

.banner-two-card {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.banner-two-bg-img {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 619px;
}

.how-wrapper {
    padding-top: 73px;
}

.card-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 63px;
    padding-bottom: 63px;
}

.right-banner-two-card {
    width: 436px;

    img {
        width: 100%;
        margin-top: -105px;
    }
}

.new-comparison {
    background-image: url('../assets/img/transparent-hero-blue.png');
    position: relative;
    margin-top: -170px;
    padding-bottom: 200px;
    z-index: -1;
}

.comparison-header {
    padding-top: 282px;

    h3 {
        color: $monthly-primary;
        font-size: 25px;
        font-weight: 600;

    }

    p {
        margin-top: 12px;
        width: 221px;
    }
}

.comparison-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1240px;

    h6 {
        font-size: 20px;
        font-weight: 500;
        color: black
    }

    p {
        font-weight: 400;
        font-size: 18px;
        color: black;
    }
}

.each-comparison-card {
    width: 378px;
    height: 700px;

    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}


.comparison-body-title {
    height: 56px;
    text-align: center;
    font-size: 30px;
    color: $monthly-primary;
    font-weight: 600;
    letter-spacing: 0.2px;

    img {
        width: 150px;
    }
}

.comparison-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.card-blue {
    background-image: url('../assets/img/card-blue.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 60px;
    height: 100%;
    box-sizing: border-box;

    div {
        height: 70px;
        display: flex;
        gap: 10px;
    }
}

.card-white {
    background-image: url('../assets/img/card-white.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 60px;
    height: 100%;
    box-sizing: border-box;
    background-repeat: no-repeat;

    div {
        height: 70px;
        display: flex;
        gap: 10px;
    }
}

.card-transparent {
    padding: 60px;
    padding-left: 0px;

    h6 {
        height: 70px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.testimonial-header {
    padding-top: 129px;
    padding-bottom: 30px;

    h3 {
        color: $monthly-primary;
        font-size: 25px;
        font-weight: 600;

    }

    p {
        margin-top: 12px;
        width: 221px;
    }
}

.testimonial-card {
    width: 478px;
    height: 285px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 30px 40px;
    text-align: left;
    color: white;

    p {
        height: 158px;
    }
}

.testimonial-card-one {
    background-image: url('../assets/img/testimonial-card-one.png');
}

.testimonial-card-two {
    background-image: url('../assets/img/testimonial-card-two.png');
}

.testimonial-card-three {
    background-image: url('../assets/img/testimonial-card-three.png');
}

.home-review-avatar {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
        width: 55px !important;
        height: 55px !important;
        border-radius: 50%;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.how-title {
    font-size: 25px;
    font-weight: 600;
    color: $monthly-primary;
}

.benefits {
    background-color: $white;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;

    &-heading {
        font-weight: 600;
        font-size: 2.2rem;
    }

    .benefit-list {
        &-icon-box {
            background-color: rgba(23, 0, 74, 0.09);
            width: 54px;
            height: 54px;
            border-radius: 10px;
            color: $monthly-primary;

            img {
                height: 30px;
                width: 30px;
            }
        }
    }
}

@media(max-width: 1336px) {
    .banner-container {
        width: 90%;
        margin: auto;
    }

    .new-calculator-section {
        width: 90%;
        margin: auto;
    }

    .card-flex {
        gap: 43px;
    }

    .new-flex-footer {
        flex-wrap: wrap;

    }
}

@media(max-width: 1240px) {
    // .each-comparison-card {
    //     &:first-child {
    //         display: none;
    //     }
    // }

    .left-banner {
        width: 450px;
    }

    .right-banner {
        width: 450px;
    }

    .left-calculator {
        width: 450px;
        margin: auto;
    }

    .right-calculator {
        width: 450px;
        height: 480px;
        margin: auto;
    }

    .new-calculator {
        width: 100%;
        height: 100%;
        padding: 28px 34px;
    }

    .card-flex {
        width: 650px;
        margin: auto;
        flex-wrap: wrap;
    }

    .how-title {
        text-align: center;
    }

    .calc-formgroup {
        margin-bottom: 30px;

        input {
            height: 45px;
        }

        select {
            height: 45px;
            padding: 5px 15px;
        }
    }

}

@media(max-width: 991px) {
    // .banner-wrapper {
    //     height: 843px;

    //     img {
    //         height: 843px;
    //     }
    // }

    .banner-section {
        flex-direction: column;
        padding-top: 180px;
        gap: 67px;
    }

    .left-banner {
        width: 80%;
    }

    .right-banner {
        width: 80%;

        input {
            width: 80%;
        }
    }

    .large-get-started-btn {
        display: none;
    }

    .new-calculator-section {
        flex-direction: column;
        padding-top: 250px;
        gap: 30px;
    }

    .calculator-wrapper {
        margin-top: -200px;
    }

    .left-calculator {
        width: 80%;

        h4 {
            width: 80%;
        }
    }

    .right-calculator {
        width: 80%;
    }

    .scan-title {
        display: none;
    }

    .success-qr {
        display: none;
    }

    .search-title {
        display: none;
    }

    .benefit-card {
        flex-direction: column-reverse;
        padding-top: 80px;
        gap: 100px;
        width: 80%;
        margin: auto
    }

    .benefits-container {
        height: 90%;
    }

    .benefit-bg-img {
        height: 100%;
    }

    .banner-two-bg-img {
        height: 85%;
    }

    .banner-two-card {
        flex-direction: column-reverse;
        padding-top: 80px;
        gap: 100px;
        width: 80%;
        margin: auto
    }

    .left-benefits-card {
        width: 100%;
        padding-bottom: 30px;

        h2 {
            margin-bottom: 70px
        }

        h6 {
            width: 80%;
        }

        p {
            width: 80%;
        }

    }

    .right-benefits-card {
        display: none;
    }

    .right-banner-two-card {
        display: none;
    }

    // .comparison-header {
    //     width: 80%;
    //     margin: auto;
    // }

    // .comparison-body {
    //     width: 80%;
    //     margin: auto;
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     gap: 50px;
    // }

    .testimonial-header {
        padding-top: 80px;
    }

    .new-comparison {
        padding-bottom: 80px;
    }

}

@media(min-width:991px) {
    // .download-icons {
    //     display: none;
    // }

    .small-success-title {
        display: none;
    }

    .large-get-started-btn {
        color: white;
        background-color: $monthly-green;
        width: 190px;
        height: 60px;
        border-radius: 6px;
        border-color: transparent;
        letter-spacing: 0.1em;
        font-weight: 500;
        margin-top: 69px;
    }

    .agent-download-icons {
        display: none;
    }

}

@media(max-width: 768px) {

    .benefits-container {
        height: 90%;
    }

    .banner-two-bg-img {
        height: 90%;
    }

    .banner-section {
        padding-top: 142px;
    }

    .banner-container {
        width: 90%;
    }

    .card-flex {
        width: 100%;
        justify-content: center;
        margin: auto;
    }

    .how-title {
        padding-top: 0px;
    }

    .success-qr {
        display: none;
    }

    .search-title {
        display: none;
    }

    .agent-download-icons {
        display: flex;
        width: 300px;
        margin: auto;
        justify-content: space-between;
        padding-top: 30px;

        div {
            width: 141px;

            img {
                width: 141px;
            }
        }
    }

    .agent-referral-link-container {
        flex-direction: column;
        gap: 40px;
    }

    .agent-referral-link {
        margin: auto;
    }
}

@media(min-width: 576px) {
    .home-download-icons {
        display: none;
    }
}

@media(max-width: 576px) {
    .benefit-card {
        padding-top: 48px;
    }

    .banner-two-card {
        padding-top: 48px;
    }

    .left-benefits-card {
        padding-bottom: 30px;

        h2 {
            font-size: 36px;
            margin-bottom: 45px;
        }

        h4 {
            font-size: 36px;
            padding-bottom: 15px;
        }

        h6 {
            width: 100%;
        }

        h5 {
            display: none;
        }

        p {
            width: 100%;
        }

    }

    .benefits-container {
        height: 100%;
    }

    .each-comparison-card {
        width: 235px;
        height: 580px;

        p {
            font-size: 15px;
        }
    }

    .comparison-body {
        width: 750px;

        h6 {
            font-size: 15px;
            width: 195px;
        }
    }

    .card-transparent {
        padding: 20px 0px 20px 0px;

        h6 {
            height: 58px;
        }
    }

    .card-blue {
        background-image: url('../assets/img/card-blue-sm.png');
        padding: 20px 10px 20px 20px;

        div {
            height: 58px
        }
    }

    .card-white {
        background-image: url('../assets/img/card-white-sm.png');
        padding: 20px 10px 20px 20px;

        div {
            height: 58px;
        }
    }

    .home-review-avatar {
        gap: 10px;

        img {
            width: 44px !important;
            height: 44px !important;
            border-radius: 50%;
        }

        h4 {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .testimonial-header {
        p {
            height: 30px;
        }
    }

    .testimonial-card {
        width: 294px;
        height: 360px;
        padding: 27px 30px;

        p {
            font-size: 14px;
            font-weight: 400;
            height: 256px;
            line-height: 27px;
            margin-bottom: 0px;

        }
    }

    .testimonial-card-one {
        background-image: url('../assets/img/testimonial-card-sm-one.png');
    }

    .testimonial-card-two {
        background-image: url('../assets/img/testimonial-card-sm-two.png');
    }

    .testimonial-card-three {
        background-image: url('../assets/img/testimonial-card-sm-three.png');
    }

    .barcode-img {
        display: none;
    }

    .home-download-icons {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-wrap: wrap;
        margin: auto;
        padding-top: 30px;

        div {
            width: 140px;

            img {
                width: 140px;
            }
        }

    }

    .calculator-wrapper {
        background-image: url('../assets/img/small-hero-banner2.png');
        height: 880px;
    }

    // .banner-wrapper {
    //     height: 529px;
    //     z-index: 8;

    //     img {
    //         height: 529px;
    //     }
    // }

    .banner-section {
        padding-top: 120px;
    }

    .banner-container {
        width: 85%;
        margin: auto;
    }

    .left-banner {
        width: 100%;

        h2 {
            font-size: 30px;
            margin-bottom: 19px;
            line-height: 39px;

            span {
                font-size: 30px;
            }
        }

        p {
            font-size: 15px;
            font-weight: 400;
            width: 80%;
            line-height: 25px;
        }

    }

    .right-banner {
        width: 100%;

        button {
            height: 50px;
        }

        input {
            width: 95%;
        }
    }

    .left-calculator {
        width: 100%;

        h4 {
            font-size: 30px;
            width: 100%;
        }

        p {
            font-size: 15px;
            font-weight: 400;
            width: 80%;
        }

    }

    .right-calculator {
        width: 100%;
    }

    .new-calculator {
        padding: 19px 38px;
    }

    .calc-formgroup {
        label {
            font-size: 12px;
            letter-spacing: 0.8px;
        }

        input {
            height: 45px;
        }
    }

    .card-flex {
        width: 300px;
        margin: auto;
    }

    .how-wrapper {
        width: 100%;
        margin: auto;
        padding-top: 100px;
    }

    .each-footer-section {
        width: 150px;

        h4 {
            font-size: 18px;
        }

        a {
            font-size: 15px;
        }

        img {
            width: 148px;
        }

    }

    .sub-footer {
        flex-wrap: wrap;
        gap: 25px;
        padding-top: 27px;
    }

    .scan-title {
        display: none;
    }

    .search-title {
        display: none;
    }

    .monthly-mobile-image {
        width: 200px;
        height: 400px;
        margin-top: 52px;
        margin-bottom: 52px;
    }

    .monthly-agent-gift {
        width: 180px;
        height: 180px;
        margin-top: 52px;
        margin-bottom: 52px;
    }


}

@media(max-width: 426px) {
    .benefit-card {
        width: 100%;
    }

    .card-flex {
        width: 100%;
        margin: auto;
    }

    // .comparison-header {
    //     width: 100%;
    // }

    // .comparison-body {
    //     width: 100%;
    // }


    .banner-two-card {
        width: 100%;
    }

    .banner-two-container {
        height: 100%;
    }

    .banner-two-bg-img {
        height: 100%;
    }
}


.comparison {
    background-color: $bg-grey;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;

    &-card {
        background-color: $white;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
        -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
        -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
        -o-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
        -ms-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 25px 20px;

        &-heading {
            font-size: 1.1rem;
        }
    }

    &-group {
        padding: 20px 0;
        width: 100%;
    }

    &-icon-wrapper {
        width: 30%;
    }

    &-icon {
        height: 21px;
        width: 84px;

        &-zed {
            height: 25px;
            width: 82px;
        }

        &-ren {
            height: 17px;
            width: 86px;
        }
    }

    &-bar-wrapper {
        width: 40%;
        text-align: left;
    }

    &-bar {
        height: 14px;

        &-monthly {
            width: 56px;
        }

        &-ren {
            width: 80px;
        }

        &-zed {
            width: 95px;
        }
    }
}

.tiles {
    background-color: $white;

    &-image {
        width: 370px;
        height: 270px;
    }

    &-section {
        width: 100%;

        &-text-box {

            &-left,
            &-right {
                padding: $mobile-horizontal-padding;
            }

            h5 {
                margin-bottom: 20px;
                font-size: 1.9rem;
            }

            h3 {
                text-align: center;
                font-size: 2.8rem;
            }
        }

        &-text-body {
            font-size: 1.4rem;
        }
    }

    &-image-box {
        text-align: center;
        margin-bottom: $mobile-horizontal-padding;
    }
}

.help {
    background: $white;
    padding: $mobile-horizontal-padding;

    &-section {
        width: 100%;
    }

    &-image {
        height: 225px;
        width: 240px;
    }

    &-text-box {
        h5 {
            margin-bottom: 20px;
            font-size: 1.9rem;
        }
    }

    &-text-body {
        font-size: 1.1rem;
    }

    &-image-box {
        margin-bottom: $mobile-horizontal-padding;
    }
}

//md screen
@media screen and (min-width: 768px) {
    .banner {
        padding: $fullscreen-vertical-padding 0 0 $fullscreen-horizontal-padding;

        &-img {
            height: 500px;
            width: 100%;
            background-image: url('../assets/img/phone-screen.webp');
            background-size: contain;
            background-position: right bottom;
        }

        &-text {
            &-heading {
                font-size: 3.1rem;
                line-height: 1.2;
            }

            &-small {
                margin-top: 50px;
            }
        }

        .partner-icon-group {
            .partner-icon {
                margin-right: 50px;

                &-mono {
                    width: 130px;
                    height: 19px;
                }

                &-smile {
                    width: 230px;
                    height: 27px;
                }

                &-dot {
                    width: 155px;
                    height: 81px;
                }
            }
        }
    }

    .benefits {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;

        &-heading {
            font-size: 2rem;
        }
    }

    .comparison {
        padding: $mobile-vertical-padding $mobile-horizontal-padding;
        color: $monthly-primary;

        &-card {
            padding: 40px 60px;

            &-heading {
                font-size: 1.1rem;
            }
        }

        &-group {
            padding: 30px 0;
        }

        &-icon-wrapper {
            width: 25%;
        }

        &-icon {
            height: 30px;
            width: 121px;

            &-zed {
                height: 41px;
                width: 130px;
            }

            &-ren {
                height: 25px;
                width: 125px;
            }
        }

        &-bar-wrapper {
            width: 50%;
            text-align: left;
            overflow-y: hidden;
        }

        &-bar {
            height: 23px;

            &-monthly {
                width: 159px;
            }

            &-ren {
                width: 226px;
            }

            &-zed {
                width: 304px;
            }
        }
    }

    .tiles {
        padding-top: $mobile-horizontal-padding;

        &-image-box {
            overflow-x: hidden;
            margin-bottom: 0;

            &-right {
                text-align: right;
            }

            &-left {
                text-align: left;
            }
        }

        &-image {
            width: 595px;
            height: 395px;
        }

        &-section {
            width: 50%;

            h3 {
                text-align: left;
                font-size: 2.8rem;
            }

            &-text-body {
                font-size: 1.4rem;
            }

            &-text-box {
                &-left {
                    padding-left: $fullscreen-horizontal-padding;
                    padding-right: $fullscreen-vertical-padding;
                    padding-bottom: $fullscreen-vertical-padding;
                }

                &-right {
                    padding-right: $fullscreen-vertical-padding;
                    padding-left: $fullscreen-vertical-padding;
                }

                h5 {
                    font-size: 1.9rem;
                }
            }
        }

    }

    .help {
        padding: $fullscreen-horizontal-padding;

        &-section {
            width: 50%;
        }

        &-image-box {
            overflow-x: hidden;
        }

        &-image {
            height: 333px;
            width: 398px;
        }

        &-text-body {
            font-size: 1.1rem;
        }

        &-text-box {
            padding-left: $fullscreen-vertical-padding;

            h5 {
                font-size: 1.9rem;
            }
        }
    }

}

//lg screen
@media screen and (min-width: 992px) {
    .banner {
        &-img {
            height: 700px;
        }

        &-text {
            &-heading {
                font-size: 3rem;
                line-height: 1.2;
            }

            padding-top: $fullscreen-vertical-padding;
        }
    }

    .tiles {
        &-section {
            &-text-box {
                &-left {
                    padding-left: $fullscreen-horizontal-padding;
                    padding-right: $fullscreen-horizontal-padding;
                }

                &-right {
                    padding-right: $fullscreen-horizontal-padding;
                    padding-left: $fullscreen-horizontal-padding;
                }
            }
        }
    }

    .comparison {
        &-bar {
            height: 23px;

            &-monthly {
                width: 227px;
            }

            &-ren {
                width: 323px;
            }

            &-zed {
                width: 434px;
            }
        }
    }

    .help {
        &-image {
            height: 417px;
            width: 498px;
        }
    }
}

//xxl screen
@media screen and (min-width: 1400px) {
    .tiles {
        &-image {
            width: 890px;
            height: 590px;
        }
    }

    .help {
        &-image {
            height: 625px;
            width: 745px;
        }
    }
}