.privacy-container {
  max-width: $custom-container-max-width;
  margin: 0 auto;
  padding: $fullscreen-vertical-padding;
  margin-bottom: 300px;
  p,
  a,
  li {
    font-size: 1.1rem;
  }

  h1, h2, h5 {
    font-weight: 600;
  }
  
  .toc-content {
    padding: 16px;
    a {
      text-decoration: none;
      color: $success;
      &:hover {
        color: $success;
      }
    }
    h2 {
      padding-top: 40px;
      margin-top: -20px;
    }
    p {
      line-height: 2.1rem;
      margin-top: 10px;
    }
    li {
      margin-top: 15px;
    }
    ol {
      margin-top: 15px;
      counter-reset: list;
      li {
        list-style: none;
        &:before {
          content: " (" counter(list, lower-alpha) ") ";
          counter-increment: list;
          margin-left: -1.4em;
        }
      }
    }
  }

  .toc-nav {
    width: 300px;
    min-width: 300px;
    padding: 0 16px 16px 0;
    margin-top: 20px;
    margin-right: 30px;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 48px;
    max-height: calc(100vh - 70px);
    overflow: auto;
    li {
      list-style-type: none;
      padding: 8px 0;
      a {
        text-decoration: none;
        line-height: 0.2rem;
        color: var(--#{$variable-prefix}body-color);
        &:hover {
          color: $success;
        }
      }
      &.active>a {
        color: $success;
      }
    }
  }

  .terms-content {
    p {
      margin-bottom: 40px;
    }
  }
  
}

//Do not show on smaller screens
@media screen and (max-width: 768px) {
  .privacy-container {
    padding: $mobile-vertical-padding $mobile-horizontal-padding;
  }
  .toc-nav {
    display: none;
  }
}
