.about {
    background-color: $monthly-primary;
    color: $white;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;
    margin-top: -5px;

    &-image {
        height: 247px;
        width: 327px;
    }

    &-image-box {
        text-align: center;
    }
}

.mission {
    background-color: $white;
    padding: $mobile-horizontal-padding;
    max-width: $custom-container-max-width;
    margin: 0 auto;
    
    h3, div{
        text-align: center;
    }
}
.about-tiles {
    background-color: $bg-grey;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;

    &-image {
        width: 241px;
        height: 226px;
    }
    &-section {
        &-text-box {
            text-align: center;
            margin-bottom: $mobile-horizontal-padding;
            padding: $mobile-horizontal-padding;

            h3 {
                text-align: center;
            }
        }
    }
    &-image-box {
        text-align: center;
    }
}



//md screen
@media screen and (min-width: 768px) { 
    .about {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;

        &-text-body {
            line-height: 1.8rem;
        }

        &-section {
            width: 50%;
        }

        &-image-box {
            text-align: right;
        }
        
        &-image {
            height: 330px;
            width: 440px;
        }

        &-text-box {
            padding-right: $fullscreen-horizontal-padding;
            padding-bottom: $fullscreen-horizontal-padding;
        }
    }

    .mission {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
    }

    .about-tiles {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
    
        &-image {
            width: 376px;
            height: 311px;
        }
        &-section {
            width: 50%;
            &-text-box {
                text-align: left;
                
                &-right{
                    padding: 0 0 0 $fullscreen-vertical-padding;
                }
                &-left {
                    padding: 0 $fullscreen-horizontal-padding 0 0;
                }
                h3 {
                    text-align: left;
                }
            }
        }
        &-image-box {
            overflow-x: hidden;
            &-left{
                text-align: left;
            }           
            &-right{
                text-align: right;
            }
            
        }
    }
    
}
//lg screen
@media screen and (min-width: 992px) { 
    .about {
        &-image {
            height: 413px;
            width: 550px;
        }
    }
    .about-tiles {    
        &-image {
            width: 417px;
            height: 389px;
        }
    }
}

//xxl screen
@media screen and (min-width: 1400px) { 
    .about {
        &-image {
            height: 536px;
            width: 715px;
        }  
    }

    .about-tiles {    
        &-image {
            width: 542px;
            height: 506px;
        }
    }
}