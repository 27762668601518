.signup-modal {
    .form-control {
        border: none;
        background-color: $home-grey;
    }

    .mc-alert {
        font-size: 1.2rem;
        text-align: center;
        &-sending {
            color: $orange;
        }
        &-success {
            color: $success;
        }
        &-error {
            color: $danger;
        }
    }
    
}