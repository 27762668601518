.faq-header {
    background-color: $monthly-primary;
    color: $white;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;
    margin-top: -5px;

}

.faq-content {
    background-color: $white;
    padding: $mobile-horizontal-padding;
    .container {
        max-width: $custom-container-max-width;      
    }
    .accordion {
        width: 100%;
        .card {
            border: none;
            background-color: $home-grey;          

            &-header {
                border: none;
                padding: 20px 30px;
            }
            &-body {
                padding: 20px 30px;
            }
        }
        &-toggle-icon {
            cursor: pointer;
        }
    }
}



//md screen
@media screen and (min-width: 768px) { 
    .faq-header {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
    }
    .faq-content {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
        .accordion {
            width: 80%;
        }
    }
}
