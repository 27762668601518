.new-signup-wrapper {
    width: 100%;
    height: 100%;
    z-index: 5;
    position: relative;
}

.signup-bg-img {
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.marketing-navbar {
    padding-top: 58px;
    position: absolute;

    img {
        width: 140px;
        height: 33px;
        position: relative;
    }
}

.signup-container {
    position: relative;
    z-index: 6;
}

.signup-link {
    color: $monthly-green;
    font-weight: 400;
    font-size: 16px;
}

.signin-support {
    color: $monthly-green;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;

}

.new-signup-section {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    padding-top: 140px;
    padding-bottom: 60px;
    position: relative;
}

.signup-left {
    width: 525px;
    text-align: left;

    h2 {
        color: $monthly-primary;
        font-weight: 600;
        font-size: 65px;
        margin-bottom: 12px;

        span {
            color: $monthly-green;
            font-weight: 600;
            font-size: 65px;
        }
    }

    p {
        color: $white;
        font-size: 20px;
        width: 382px;
    }
}

.success-tag {
    color: $monthly-green;

    span {
        color: rgba(0, 0, 0, 0.65);
    }
}

.error-tag {
    color: $red;
}

.sub-signup-left {
    margin-top: 74px;
}

.signup-back-arrow {
    color: $monthly-primary;
    font-size: 16px;
    padding-bottom: 15px;

    &:hover {
        cursor: pointer;
    }
}

.signup-review {
    margin-top: 36px;
    margin-bottom: 26px;
}

.trusted-button {
    background-color: rgba(0, 197, 152, 0.2);
    width: 274px;
    height: 45px;
    border-radius: 20px;
    border: 1px solid $monthly-green;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;

    h6 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: black;
        font-size: 15px;
    }

    span {
        color: $monthly-green;

    }

    img {
        width: 20px;
        height: 20px;
    }

}

.edit-phone-btn {
    width: 100px;
    border-color: transparent;
    margin-top: 10px;
    background-color: $monthly-green;
    border-radius: 4px;
    font-size: 12px;
    color: $white;
    height: 40px;

}

.signup-review {
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);

    span {
        font-weight: 700;
        color: rgba(0, 0, 0, 0.65);

    }
}

.review-avatar {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
        width: 55px!important;
        height: 55px!important;
        border-radius: 50%;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

// Right Section
.signup-right {
    width: 575px;
    background-color: $white;
}

.signup-form {
    width: 100%;
    box-sizing: border-box;
    padding: 44px 56px;
    background-color: $white;
    border: 1px solid rgba(23, 0, 74, 0.09);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.signup-formgroup {
    width: 100%;
    margin-bottom: 30px;

    label {
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.65);
        letter-spacing: 2px;
        margin-bottom: 10px;
    }

    input {
        width: 100%;
        height: 55px;
        border: 1px solid rgba(23, 0, 74, 0.1);
        border-radius: 10px;
        outline: 0;
        box-sizing: border-box;
        padding: 15px;
    }

    div{
        position: relative;
    }

    svg{
        position: absolute;
        right:15px;
        top: 20px;
    }

    select {
        width: 100%;
        height: 55px;
        border: 1px solid rgba(23, 0, 74, 0.1);
        border-radius: 10px;
        outline: 0;
        box-sizing: border-box;
        padding: 15px;
    }
}

.validate-password {
    input {
        height: 20px;
        color: $red;
    }
}

.signup-flexformgroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;

    div {
        width: 48%;
        box-sizing: border-box;

        label {
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.65);
            letter-spacing: 2px;
            margin-bottom: 10px;
        }

        input {
            width: 100%;
            height: 55px;
            border: 1px solid rgba(23, 0, 74, 0.1);
            border-radius: 10px;
            outline: 0;
            box-sizing: border-box;
            padding: 15px;
        }
    }
}

.signup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $monthly-green;
    color: white;
    font-size: 16px;
    letter-spacing: 2px;
    width: 100%;
    height: 60px;
    border-radius: 6px;
    border-color: transparent;
    margin-top: 16px;

    &:disabled {
        color: black;
        background: rgba(0, 197, 152, 0.6);
    }
}

.new-signup-title {
    color: $monthly-primary;
    font-size: 30px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 18px;
    text-align: center;
}

.new-signup-subtitle {
    font-size: 35px;
    font-weight: 600;
    color: $monthly-green;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-align: center;
    margin-bottom: 68px;

    svg {
        width: 22px;
        height: 22px;

        &:hover {
            cursor: pointer;
        }
    }
}

.otp-subfooter {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
    margin-top: 49px;

    div {
        color: $monthly-green;
        font-size: 15px;
        font-weight: 500;

        &:hover {
            cursor: pointer;
        }
    }

    svg {
        width: 14px;
        height: 13px;
    }
}

// Media Queries

@media(min-width: 1366px) {
    .new-signup-container {
        width: 1240px;
        margin: auto;
    }


}

@media(max-width: 1366px) {
    .new-signup-container {
        width: 90%;
        margin: auto;
    }

}

@media(max-width:1240px) {
    .signup-left {
        width: 450px;
    }

    .signup-right {
        width: 450px;
    }

    .signup-flexformgroup {
        flex-direction: column;
        margin-bottom: 0px;
        div {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

@media(min-width: 991px) {
    .mobile-signup-header {
        display: none;
    }
}

@media(max-width:991px) {
    .new-signup-section {
        flex-direction: column-reverse;
    }

    .mobile-signup-header {
        margin-bottom: 44px;
        color: $monthly-primary;
        font-weight: 600;
        font-size: 65px;
        margin-bottom: 12px;

        span {
            color: $monthly-green;
            font-weight: 600;
            font-size: 65px;
        }
    }

    .sub-signup-left {
        margin-top: 40px;
    }

    .signup-left {
        width: 80%;

        h2 {
            display: none;
        }
    }

    .signup-right {
        width: 80%;
    }

}

@media(max-width: 768px) {
    .mobile-signup-header {
        font-size: 42px;

        span {
            font-size: 42px;
        }
    }

    .new-signup-section {
        padding-top: 120px;
    }

    .signup-form {
        padding: 30px 20px;
    }
}

@media(max-width: 576px) {
    .new-signup-container {
        width: 85%;
        margin: auto;
    }

    .mobile-signup-header {
        font-size: 30px;

        span {
            font-size: 30px;
        }
    }

    .signup-left {
        width: 100%;

    }

    .otp-subfooter{
        gap: 30px;
    }

    .signup-right {
        width: 100%;

    }

    .signup-formgroup {
        margin-bottom: 24px;

        label {
            font-size: 12px;
            letter-spacing: 1.5px;
            margin-bottom: 8px;
        }

        input {
            height: 40px;
            border-radius: 5px;
            padding: 5px;
            font-size: 14px;
        }

        select {
            height: 40px;
            border-radius: 5px;
            padding: 5px;
            font-size: 14px;
        }

        svg{
            top: 13px;
        }
    }

    .signup-flexformgroup {

        div {
            margin-bottom: 24px;

            label {
                font-size: 12px;
                letter-spacing: 1.5px;
                margin-bottom: 8px;
            }

            input {
                height: 40px;
                border-radius: 5px;
                padding: 5px;
                font-size: 14px;
            }
        }
    }

    .small-formgroup {
        input{
            padding: 5px;
            font-size: 14px;
        }
    }

    .new-signup-title {
        font-size: 24px;
        margin-bottom:15px;
    }
    .new-signup-subtitle {
        margin-bottom: 20px;
    }

    .signup-button {
        height: 45px;
        border-radius: 5px;
    }
}