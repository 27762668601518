.apply-btn {
    background-color: $monthly-green;
    width: 350px;
    height: 60px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border-color: transparent;
    color: white;
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: 500;
}

.upload-section{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
    .upload--box{
      border:2px dashed #efefef;
      padding: 10px;
      cursor: pointer;
    }
    .doc--label{
      text-transform: capitalize;
    }
    .upload--input .form-control[type=file]{
      opacity: 0;
      margin-top: -34px;
    }
    &_msg{
      margin-top: 100px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      a{
        text-decoration: none;
      }
    }
  }

.upload-label {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.65);
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.upload-container {
    border: 2px dashed #efefef;
    border-radius: 10px;
    padding: 15px;
    height: 55px;
    cursor: pointer;
}

.apply-back-nav {
    position: absolute;
    top: 150px;
}

.apply-back-nav:hover {
    cursor: pointer;
}

.apply-card {
    padding: 40px 80px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.apply-calc-container {
    border-radius: 10px;
    background-color: rgba(23, 0, 74, 0.05);
    padding: 14px 23px;
    border: 1px solid rgba(23, 0, 74, 0.09);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.income-btn {
    font-size: 18px;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    outline: 0;
    border: transparent;
}

.file-upload-container {
    display: flex;
    align-items: center;
    position: relative;

    input {
        height: 100%;
    }

    svg {
        position: absolute;
        right: 20px;
    }
}

.tooltip-text {
    font-size: 11px;
}

.item-pair {
    p {
        font-size: 18px;
        color: #979797;
        margin-bottom: 10px;
    }

    h6 {
        color: black;
        font-weight: 600;
        margin-bottom: 10px;
    }

    h5 {
        color: $monthly-green;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    h3 {
        color: $monthly-primary;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    h4 {
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 12px;
    }
}

.apply-form-container {
    width: 575px;
    margin: auto;
    padding-top: 150px;
}

.approval-status-btn {
    height: 51px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #F4F3F3;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #4F4A4A;

}

.self-employed-error {
    width: 100%;
    background-color: #E33C2F;
    color: white;
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: -10px;
    margin-bottom: 20px;
}

.status-btn {
    &__rejected {
        background-color: #FFF1F0;
        color: #E33C2F;
    }

    &__in-application {
        background-color: #FFFBD8;
        color: #C5B100;
    }

    &__pre-approved {
        background-color: #FFFBD8;
        color: #C5B100;
    }

    &__awaiting-decision {
        background-color: #F4F3F3;
        color: #4F4A4A;
        // background-color: #FFFBD8;
        // color: #C5B100;
    }

    &__active {
        background-color: #EEFFFB;
        color: #00C598;
    }
}


.pending-container {
    width: 863px;
    margin: auto;
    padding-top: 100px;
    padding-bottom: 30px;
}

.verify-btn {
    background-color: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    text-align: left;
    border-color: transparent;
    border-radius: 5px;

    h2 {
        font-weight: 600;
        font-size: 20px;
    }

    p {
        color: #777676;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 0px;

    }
}

.verify-text {
    width: 85%;
}

.verify-icon {
    background-color: #ECECEC;
    font-size: 25px;
    width: 41px;
    height: 49px;
    border-radius: 8px;
    color: $monthly-primary;
    display: flex;
    align-items: center;
    justify-content: center;

}

.apply-checkbox {
    color: #777676;
    font-weight: 400;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 5;

    label {
        margin-left: 13px;
    }

    a {
        color: $monthly-green;
    }
}

.apply-reason-container {
    width: 300px;
    margin: auto;
    margin-bottom: 15px;
    margin-top: 15px;

    h6 {
        color: #e33c2f;
        line-height: 23px;
        font-size: 14px;
    }

    p {
        color: #00000030;
        font-size: 12px;

    }

    h3 {
        color: $monthly-primary;
        font-size: 14px;
        font-weight: 500;
    }

    a {
        color: #AFAFAF;
        font-size: 12px;
        text-align: center;
    }
}

.complete-kyc-btn {
    position: relative;
    z-index: 5;
}

.cam-container {
    position: relative;
    z-index: 20;
}

@media(max-width: 991px) {
    .pending-container {
        width: 575px;
        padding-top: 75px;
    }

}

@media(max-width: 768px) {
    .pending-container {
        width: 90%;
    }

    .approval-status-btn {
        font-size: 16px;
    }

    .verify-btn {
        h2{
            font-size: 18px;
        }
    }
}

@media(max-width: 576px) {
    .verify-btn {
        h2 {
            font-size: 16px;
        }
    }

    .apply-card {
        padding: 30px 20px;
    }

    .income-btn {
        font-size: 14px;
    }

    .approval-status-btn {
        padding: 10px;
        font-size: 14px;
    }

    .apply-checkbox label {
        font-size: 12px;
    }

    .upload-container {
        border: 2px dashed #efefef;
        border-radius: 5px;
        height: 40px;
        padding: 5px 10px;
    }

    .verify-text {
        width: 70%;
    }

    .file-upload-container {
        svg {
            position: absolute;
            right: 10px;
            top: 20px;
        }
    }

    .verify-text {
        width: 80%;
    }

    .pending-container {
        width: 100%;
        padding-top: 60px;
    }

    .apply-form-container {
        width: 100%;
    }

    .apply-calc-container {
        padding: 14px;
    }

    .item-pair {
        h3 {
            font-size: 12px;
        }
    }
}

@media(max-width: 426px) {
    .apply-btn {
        width: 100%;
    }

    .verify-btn {
        padding: 12px;
    }

    .apply-card {
        padding: 30px 10px;
    }

    .item-pair {
        p{
            font-size: 14px
        }

        h5{
            font-size: 14px;
        }
    }

    .verify-icon {
        font-size: 18px;
        width: 25px;
        height: 40px;
        border-radius: 4px;
    }

    .verify-text {
        margin-bottom: 0px;

        h2{
            font-size: 12px;
        }
    }
}