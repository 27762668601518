@font-face {
    font-family: 'GraphikRegular';
    src: url('../assets/fonts/GraphikRegular/GraphikRegular.eot');
    src: url('../assets/fonts/GraphikRegular/GraphikRegular.eot') format('embedded-opentype'),
         url('../assets/fonts/GraphikRegular/GraphikRegular.woff2') format('woff2'),
         url('../assets/fonts/GraphikRegular/GraphikRegular.woff') format('woff'),
         url('../assets/fonts/GraphikRegular/GraphikRegular.ttf') format('truetype'),
         url('../assets/fonts/GraphikRegular/GraphikRegular.svg#GraphikRegular') format('svg');
}

@font-face {
    font-family: 'GraphikMedium';
    src: url('../assets/fonts/GraphikMedium/GraphikMedium.eot');
    src: url('../assets/fonts/GraphikMedium/GraphikMedium.eot') format('embedded-opentype'),
         url('../assets/fonts/GraphikMedium/GraphikMedium.woff2') format('woff2'),
         url('../assets/fonts/GraphikMedium/GraphikMedium.woff') format('woff'),
         url('../assets/fonts/GraphikMedium/GraphikMedium.ttf') format('truetype'),
         url('../assets/fonts/GraphikMedium/GraphikMedium.svg#GraphikMedium') format('svg');
}

// scss-docs-start import-stack
// Configuration

@import "mixins";
@import "functions";

@import "variables";

@import "reboot";
@import "utilities";

@import "helpers";
@import "root";

// Layout & components
@import "components";
@import "pages";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack


// Begin custom styles here!
.text-bold{
    font-weight: 600;
}
.hidden {
    display: none;
}
.validation-message {
    font-size: 0.9rem;
}

.new-navigation {
    padding-top: 50px;
    flex: auto;
    position: absolute;
    background-color: transparent;
    z-index: 45;
    &-logo{
        width: 103px;
        height: 25px;
    }
    .navbar-toggler {
        padding: 0;
        z-index: 6;
    }
    .navbar-nav {
        text-align: center;
        .nav-link {
            padding-left: 2rem;
            padding-right: 2rem;
            &:last-of-type {
                padding-right: 2rem;
            }

        }
    }

    @media (min-width: 1336px) {
      flex: none;
      width: 1240px;
      left: 0;
      right: 0;
      margin: auto;
    }
    @media (max-width: 1336px) {
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media (max-width: 991px) {
      padding: 0px;
      width: 80%;
      left: 0;
      right: 0;
      justify-content:center;
      padding-top: 30px;
      margin-bottom: 30px;
      .fixed-toggler {
        position: fixed;
        right: 30px;
        top: 40px;

      }
      .collapse-container {
        position: fixed;
        padding-top: 60px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(23, 0, 74, 0.9);
        z-index: 5;
        width: 100%;
      }
      .navbar-nav {
        text-align: left;
      }
    }
    @media (max-width: 768px) {
      width: 90%;
    }
}
.page-padding {
    padding-top: 150px;
}
.navigation {
    padding: $mobile-horizontal-padding;
    &-logo{
        width: 103px;
        height: 25px;
    }
    .navbar-toggler {
        padding: 0;
    }

    .navbar-nav {
        text-align: center;
        .nav-link {
            padding-left: 2rem;
            padding-right: 2rem;
            &:last-of-type {
                padding-right: 2rem;
            }

        }
    }
    
}
.not-found {
    h1 {
        font-size: 4rem;
    }
    &-icon {
        font-size: 6rem;
    }
    &-icon-medium {
        font-size: 3rem;
    }
}

// $mobile-horizontal-padding: 25px;
// $mobile-vertical-padding: 50px;
// $fullscreen-horizontal-padding: 100px;
// $fullscreen-vertical-padding: 50px;
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// );

//sm screen
@media screen and (min-width: 576px) { 
    .navigation {
        .navbar-nav {
            .nav-link {
                padding-top: 1rem;
                padding-bottom: 1rem;
                &:last-of-type {
                    padding-right: 2rem;
                }
            }
        }
    }

    .new-navigation {
        .navbar-nav {
            .nav-link {
                padding-top: 1rem;
                padding-bottom: 1rem;
                &:last-of-type {
                    padding-right: 2rem;
                }
            }
        }
    }
    
}
//md screen
@media screen and (min-width: 768px) { 
    .navigation {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
        &-logo{
            width: 156px;
            height: 37px;
        }
        .navbar-nav {
            .nav-link {
                &:last-of-type {
                    padding-right: 0;
                }
            }
        }
    }
    .new-navigation {
        &-logo{
            width: 156px;
            height: 37px;
        }
        .navbar-nav {
            .nav-link {
                &:last-of-type {
                    padding-right: 0;
                }
            }
        }
    }

    .not-found {
        h1 {
            font-size: 6rem;
        }
        &-icon {
            font-size: 10rem;
        }
        &-icon-medium {
            font-size: 6rem;
        }
    }
}
//lg screen
@media screen and (min-width: 992px) { 


}