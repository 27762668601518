.agent-banner-wrapper {
    width: 100%;
    z-index: 5;
    position: relative;
    background: rgba(23, 0, 74, 0.03);
}

.agent-banner-container {
    z-index: 1;
}

.agent-banner-section {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    padding-top: 220px;
    position: relative;
}

.agent-left-banner {
    width: 525px;
    position: relative;

    img {
        width: 525px;
        position: relative;
        z-index: 1;
    }
}

.agent-banner-bg {
    background: rgba(0, 197, 152, 0.08);
    width: 340px;
    height: 576px;
    position: absolute;
    z-index: 0;
    left: -68px;
    top: -55px;
}

.agent-right-banner {
    width: 600px;
    text-align: left;

    h2 {
        color: $monthly-primary;
        font-weight: 800;
        font-size: 55px;
        line-height: 78px;
        margin-bottom: 12px;
    }

    p {
        color: $monthly-primary;
        font-size: 20px;
        width: 450px;
        margin-bottom: 25px;
    }
}

.agent-left-banner-two {
    width: 600px;
    text-align: left;

    h2 {
        font-weight: 800;
        font-size: 40px;
        line-height: 57px;
        padding-top: 60px;
        margin-bottom: 20px;
    }

    button {
        color: white;
        background-color: $monthly-green;
        width: 190px;
        height: 60px;
        border-radius: 6px;
        border-color: transparent;
        letter-spacing: 0.1em;
        font-weight: 500;
    }
}

.agent-right-banner-two {
    width: 525px;
    position: relative;

    img {
        width: 525px;
        position: relative;
        z-index: 1;
    }
}

.agent-banner-bg-two {
    background: rgba(0, 197, 152, 0.08);
    width: 340px;
    height: 576px;
    position: absolute;
    z-index: 0;
    right: -68px;
    top: -55px;
}

.agent-sub-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 111px;
}

.agent-banner-overflow {
    position: absolute;
    left: -800px;
    width: 800px;
    background: rgba(0, 197, 152, 0.08);
    height: 336px;
    z-index: 0;
}

.left-agent-sub {
    width: 525px;
    height: 336px;
    background: rgba(0, 197, 152, 0.08);
    position: relative;
    z-index: 1;
    padding-right: 75px;
    box-sizing: border-box;

    h2 {
        font-weight: 800;
        font-size: 40px;
        line-height: 57px;
        padding-top: 60px;
    }

    p {
        font-size: 20px;
        margin-top: 20px;
    }
}

.right-agent-sub {
    height: 336px;
    width: 600px;

    h2 {
        font-weight: 800;
        font-size: 40px;
        line-height: 57px;
        padding-top: 60px;
    }
}


// how section

.why-card {
    width: 295px;
    height: 395px;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 45px;
    padding-top: 41px;

    .why-icon {
        height: 70px;
    }

    h5 {
        color: $monthly-primary;
        height: 99px;
        margin-top: 25px;
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 29px;
        font-weight: 500;
    }

    p {
        font-size: 15px;
        line-height: 23px;
        font-weight: 400;
    }
}

.card-one {
    background-color: rgba(23, 0, 74, 0.1);

    h5 {
        width: 190px;
    }
}

.card-two {
    background-color: rgba(0, 197, 152, 0.1);

    h5 {
        width: 150px;
    }
}

.card-three {
    background-color: rgba(120, 139, 255, 0.1);

    h5 {
        width: 150px;
    }
}

.card-four {
    background-color: rgba(202, 235, 255, 0.2);

    h5 {
        width: 100px;
    }
}

// How monthly works

.works-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}
.works-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    @media(max-width: 991px){
        flex-direction: column;
        gap: 40px;
    }
}

.works-img {
    width: 100%;
    @media(max-width: 991px){
        text-align: center;
    }
}

.styled-vertical-timeline {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.styled-vertical-timeline {
    ::before {
        height: 80%;
    }
}

.timeline-element-title {
    font-size: 25px;
    font-weight: 700;
    color: $monthly-primary;
}

.works-vertical-section {
    padding-right: 50px;
    width: 70%;
    @media(max-width: 991px){
        padding-right: 0px;
        width: 80%;
    }
    @media(max-width: 576px){
        width: 100%;
    }
}

.works-heading {
    text-align: center;
    padding-top: 50px;
    h2{
        color: $monthly-primary;
        font-size: 38px;
        font-weight: 800;
        margin-bottom: 20px;
    }
    p{
        font-size: 20px;
        font-weight: 400;
    }
    @media(max-width: 991px){
        h2{
            width: 80%;
            margin:auto;
            margin-bottom: 20px;
        }
    }
}

// Reviews on Home page
.agent-review-heading {
    font-size: 35px;
    font-weight: 800;
    color: rgba(23, 0, 74, 0.38);
    width: 920px;
    text-align: left;
}

.agent-review-container {
    background: rgba(23, 0, 74, 0.03);
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
}

.review-card-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 77px;
    margin-bottom: 120px;

}

.review-card {
    background: $white;
    border: 0.6px solid rgba(207, 207, 207, 0.5);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    width: 570px;
    padding: 29px 35px 37px 40px;
    box-sizing: border-box;
    text-align: left;

    h4 {
        color: $monthly-primary;
        font-weight: 700;
        font-size: 28px;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        color: rgba(23, 0, 74, 0.56);
        height: 120px;
    }
}

.card-review-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ratings-container {
    display: flex;
    align-items: baseline;
    gap: 20px;
}

.agent-sign-up-button {
    color: white;
    background-color: $monthly-green;
    width: 190px;
    height: 60px;
    margin: auto;
    border-radius: 6px;
    border-color: transparent;
    text-decoration: none;
    letter-spacing: 0.1em;
    font-weight: 500;
    :hover{
        cursor: pointer;
    }
}

// Marketing footer section 
.new-footer-wrapper {
    padding-top: 100px;
    padding-bottom: 83px;

    hr {
        background-color: $monthly-green;
    }
}

.new-flex-footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
}

.new-footer-logo {
    width: 150px;
    height: 36px;
}

.each-footer-section {
    width: 180px;

    div {
        padding-top: 28px;
    }

    h4 {
        color: $monthly-green;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
    }

    a {
        color: $monthly-dark-ash;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
    }
}

.footer-icon-section {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        font-size: 25px;
        color: $monthly-green;
    }
}

.sub-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 42px;

    div {
        font-size: 16px;
        font-weight: 500;
    }

    span {
        font-size: 18px;
        font-weight: 400;
    }
}

// Success Page 
.success-wrapper {
    width: 100%;
    height: 100%;
    z-index: 5;
    position: relative;
}

.success-bg-img {
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.monthly-mobile-image {
    width: 250px;
    height: 500px;
    margin-top: 77px;
    margin-bottom: 77px;
}

.monthly-agent-gift {
    width: 300px;
    height: 300px;
    margin-top: 77px;
    margin-bottom: 77px;
}

.scan-title {
    color: $monthly-primary;
    font-size: 45px;
    font-weight: 600;
    margin: auto;
    margin-bottom: 77px;
    width: 753px;

    span {
        color: $monthly-green;
    }
}

.search-title {
    color: $monthly-primary;
    font-size: 40px;
    font-weight: 600;
    margin: auto;
    margin-bottom: 18px;
    text-align: center;

    span {
        color: $monthly-green;
    }
}

.success-qr {
    width: 200px;
    height: 200px;
}

.small-success-title {
    color: $monthly-primary;
    font-size: 25px;
    font-weight: 600;

    span {
        color: $monthly-green;
    }
}

.success-alert {
    background-color: rgba(0, 197, 152, 1);
    width: 274px;
    margin: auto;
    height: 45px;
    border-radius: 20px;
    border: 1px solid $monthly-green;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;

    h6 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: black;
        font-size: 15px;
    }

    span {
        color: $monthly-green;

    }

    img {
        width: 20px;
        height: 20px;
    }

}

.download-icons {
    display: flex;
    width: 300px;
    margin: auto;
    justify-content: space-between;
    padding-top: 30px;

    div {
        width: 141px;

        img {
            width: 141px;
        }
    }
}



@media(min-width: 1336px) {
    .agent-banner-container {
        width: 1240px;
        margin: auto;
    }

    .new-calculator-section {
        width: 1240px;
        margin: auto;
    }

    // .page-container {
    //     width: 1240px;
    //     margin: auto;
    // }


}

@media(max-width: 1336px) {
    .agent-banner-container {
        width: 90%;
        margin: auto;
    }

    // .page-container {
    //     width: 90%;
    //     margin: auto;
    // }

    .new-flex-footer {
        flex-wrap: wrap;

    }
}

@media(max-width: 1240px) {
    .agent-left-banner {
        width: 450px;

        img {
            width: 440px;
            padding-right: 10px;
        }
    }

    .agent-left-banner-two {
        width: 85%;
    }

    .agent-right-banner-two {
        width: 450px;
        position: relative;

        img {
            width: 440px;
            padding-left: 10px;
        }
    }

    .agent-right-banner {
        width: 85%;
    }

    .agent-banner-bg {
        height: 500px;
    }

    .agent-banner-bg-two {
        height: 480px;
    }

    .left-agent-sub {
        width: 440px;
    }

    .right-agent-sub {
        width: 440px;
    }

    .review-card-container {
        gap: 30px;;
    }

    .review-card {
        width: 85%;
    }

}

@media(max-width: 991px) {
    // .banner-wrapper {
    //     height: 843px;

    //     img {
    //         height: 843px;
    //     }
    // }

    .agent-banner-section {
        flex-direction: column-reverse;
        padding-top: 160px;
        gap: 100px;
        width: 80%;
        margin: auto
    }

    .agent-left-banner {
        width: 80%;
    }

    .agent-right-banner {
        width: 80%;
    }

    .agent-left-banner-two {
        width: 80%;
    }

    .agent-right-banner-two {
        width: 80%;
    }


    .agent-sub-banner {
        flex-direction: column;
        width: 100%;
    }

    .agent-banner-bg-two {
        height: 480px;
    }

    .agent-banner-overflow {
        display: none;
    }

    .left-agent-sub {
        width: 80%;
        margin: auto;
        padding-right: 0px;

        h2,
        p {
            width: 80%;
            margin: auto;
        }
    }

    .right-agent-sub {
        width: 80%;
        margin: auto;

        h2 {
            width: 80%;
            margin: auto;
        }
    }

    .agent-review-heading{
        width: 80%;
        margin: auto;
    }

    .review-card-container {
        flex-wrap: wrap;
        justify-content: center;
    
    }

    .large-get-started-btn {
        display: none;
    }

    .scan-title {
        display: none;
    }

    .success-qr {
        display: none;
    }

    .search-title {
        display: none;
    }

}

@media(min-width:991px) {
    // .download-icons {
    //     display: none;
    // }

    .small-success-title {
        display: none;
    }

    .large-get-started-btn {
        color: white;
        background-color: $monthly-green;
        width: 190px;
        height: 60px;
        border-radius: 6px;
        border-color: transparent;
        letter-spacing: 0.1em;
        font-weight: 500;
        margin-top: 69px;
    }

    .agent-download-icons {
        display: none;
    }

}

@media(max-width: 768px) {
    .agent-banner-section {
        padding-top: 122px;
    }

    .agent-banner-container {
        width: 90%;
    }

    .agent-right-banner {
        width: 100%;

    }

    .agent-left-banner-two {
        width: 100%;

    }

    .agent-right-banner-two {
        width: 100%;

        img {
            width: 100%;
        }

    }

    .agent-left-banner {
        width: 100%;

        img {
            width: 100%;
        }

    }

    .agent-banner-bg {
        img {
            width: 100%;
        }
    }

    .agent-banner-bg-two {
        img {
            width: 100%;
        }
    }

    .left-agent-sub {
        h2 {
            font-size: 32px;
            line-height: 45px;
        }
    }

    .right-agent-sub {
        h2 {
            font-size: 32px;
            line-height: 45px;
        }
    }

    .success-qr {
        display: none;
    }

    .search-title {
        display: none;
    }

    .agent-download-icons {
        display: flex;
        width: 300px;
        margin: auto;
        justify-content: space-between;
        padding-top: 30px;

        div {
            width: 141px;

            img {
                width: 141px;
            }
        }
    }

}

@media(max-width: 576px) {
    .agent-banner-section {
        width: 100%;
    }

    .agent-right-banner {
        h2 {
            font-size: 40px;
            line-height: 56px;
        }

        p {
            width: 80%;
        }
    }

    .agent-left-banner-two {
        h2 {
            font-size: 30px;
            line-height: 42px;
            padding-top: 0px;
        }
    }

    .agent-left-banner {
        h2 {
            font-size: 40px;
            line-height: 56px;
        }
    }

    // .banner-wrapper {
    //     height: 529px;
    //     z-index: 8;

    //     img {
    //         height: 529px;
    //     }
    // }

    .banner-container {
        width: 85%;
        margin: auto;
    }

    .agent-banner-bg {
        height: 80%;
    }

    .agent-banner-bg-two {
        height: 80%;
    }

    .left-agent-sub {
        width: 100%;

        h2,
        p {
            width: 90%;
        }
    }

    .right-agent-sub {
        width: 100%;

        h2 {
            width: 90%;
            font-size: 28px;
            line-height: 40px;
        }
    }

    .agent-sub-banner {
        margin-top: 40px;
    }

    .left-banner {
        width: 100%;

        h2 {
            font-size: 30px;
            margin-bottom: 19px;
            line-height: 39px;

            span {
                font-size: 30px;
            }
        }

        p {
            font-size: 15px;
            font-weight: 400;
            width: 80%;
            line-height: 25px;
        }

    }

    .right-banner {
        width: 100%;

        button {
            height: 50px;
        }

        input {
            width: 95%;
        }
    }

    .agent-review-heading {
        width: 100%;
        font-size: 30px;
    }

    .review-card {
        width: 100%;
    }

    .card-review-footer {
        flex-wrap: wrap;
        margin-top: 15px;
        gap: 10px;
    }

    .each-footer-section {
        width: 150px;

        h4 {
            font-size: 18px;
        }

        a {
            font-size: 15px;
        }

        img {
            width: 148px;
        }

    }

    .sub-footer {
        flex-wrap: wrap;
        gap: 25px;
        padding-top: 27px;
    }

    .scan-title {
        display: none;
    }

    .search-title {
        display: none;
    }

    .monthly-mobile-image {
        width: 200px;
        height: 400px;
        margin-top: 52px;
        margin-bottom: 52px;
    }

    .monthly-agent-gift {
        width: 180px;
        height: 180px;
        margin-top: 52px;
        margin-bottom: 52px;
    }


}

@media(max-width: 426px){
    .review-card{
        p{
            font-size: 12px;
            height: 80px;
        }
    }

}