.footer{
    margin-bottom: 0;
    border: none;
    background-color: $monthly-primary;
    color: $white;
    font-size: 1rem;
    padding: $mobile-horizontal-padding;
    &-logo{
        width: 100px;
        height: 25px;
    }

    a {
        color: $white;
        text-decoration: none;
        &:hover {
            color: $monthly-green;
        }
    }

    .socials-wrapper {
        .footer-logo {
            margin-bottom: 36px;
        }

        .socials-icon-group {
            margin-top: 30px;

            .socials-icon {
                height: 24px;
                width: 24px;
                background-color: $white;
                margin-right: 10px;
                border-radius: 50%;
                font-size: 0.8rem;

                a {
                    color: $monthly-primary;
                    &:hover {
                        color: $monthly-green;
                    }
                }
            }
        }
    }

}



//sm screen
@media screen and (min-width: 576px) { 

    
}
//md screen
@media screen and (min-width: 768px) { 
    .footer {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;

        &-logo{
            width: 130px;
            height: 31px;
        }

        .socials-wrapper {
            margin-bottom: 120px;
        }


    }
    
    
}
//lg screen
@media screen and (min-width: 992px) { 

}
