.faq-header {
    background-color: $monthly-primary;
    color: $white;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;
    margin-top: -5px;

}

.how-to {
    background-color: $white;
    padding: $mobile-horizontal-padding;
    padding-right: 0 ;
    &-section {
        width: 100%;
    }
    &-image-box {
        text-align: right;
        overflow: hidden;
        display: none;
    }
    &-image{
        width: 253px;
        height: 435px;
    }
    &-icon {
        width: 40px;
        height: 40px;
    }
    &-description {
        padding-right: $mobile-vertical-padding;

        .vertical-timeline{        
            &::before{
                width: 2px;
                height: 83%;
                margin-top: 40px;
            }
        }
        .vertical-timeline-element-content{
            box-shadow: none;
            padding-top: 0.5em;
            p {
                font-weight: 400;
                font-size: 1.1rem;
            }
        }
        .vertical-timeline-element {
            margin: 2rem 0;
        }
    }
}

.how-to-tiles {
    background-color: $bg-grey;
    padding: $mobile-vertical-padding $mobile-horizontal-padding;

    &-image {
        width: 241px;
        height: 226px;
    }
    &-section {
        &-text-box {
            margin-bottom: $mobile-horizontal-padding;
            padding: $mobile-horizontal-padding;
            padding-bottom: 0;

            h3 {
                text-align: center;
            }
        }
    }
    &-image-box {
        text-align: center;
    }
    &-check {
        color: #c5c5c5;
    }
}



//md screen
@media screen and (min-width: 768px) { 
    .faq-header {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
    }
    .how-to {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
        padding-right: 0;

        &-section {
            width: 50%;      
        }
        &-image-box {
            text-align: right;
            overflow: hidden;
            display: block;
        }
        &-image{
            width: 345px;
            height: 637px;
        }
        &-description {
            .vertical-timeline-element-content{
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .how-to-tiles {
        padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
    
        &-image {
            width: 376px;
            height: 311px;
        }
        &-section {
            width: 50%;
            &-text-box {
                text-align: left;
                
                &-right{
                    padding: 0 0 0 $fullscreen-vertical-padding;
                }
                &-left {
                    padding: 0 $fullscreen-horizontal-padding 0 0;
                }
                h3 {
                    text-align: left;
                }
            }
        }
        &-image-box {
            overflow-x: hidden;
            &-left{
                text-align: left;
            }           
            &-right{
                text-align: right;
            }
            
        }
    }
    
}

//lg screen
@media screen and (min-width: 992px) { 
    .how-to {
        &-description {
            padding: $fullscreen-vertical-padding;
        }
        &-image{
            width: 416px;
            height: 738px;
        }
    }
    .how-to-tiles {    
        &-image {
            width: 417px;
            height: 389px;
        }
    }
}

//xxl screen
@media screen and (min-width: 1400px) { 
    .how-to {
        &-description {
            padding: $fullscreen-vertical-padding $fullscreen-horizontal-padding;
        }
        &-image{
            width: 577px;
            height: 1002px;
        }
    }
    .how-to-tiles {    
        &-image {
            width: 542px;
            height: 506px;
        }
    }
}
