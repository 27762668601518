.top--logo{
  display: flex;
  justify-content: center;
  padding-top: 58px;
  img{
    width: 140px;
    height: 33px;
  }
}
.upload--section{
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
  .upload--box{
    border:2px dashed #efefef;
    padding: 10px;
    cursor: pointer;
  }
  .doc--label{
    text-transform: capitalize;
  }
  .upload--input .form-control[type=file]{
    opacity: 0;
    margin-top: -34px;
  }
  &_msg{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    a{
      text-decoration: none;
    }
  }
}
.upload--btn{
  background-color: $monthly-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  letter-spacing: 2px;
  width: 100%;
  padding: 15px 10px;
  border-radius: 6px;
  border-color: transparent;
  margin-top: 16px;
  text-align: center;
  &:disabled{
    background: rgba(23 ,0 ,74, 0.4);
    color: white;
  }
}
.new-upload-wrapper{
  width: 50%;
  margin: 0 auto;
}