.banner-wrapper {
    width: 100%;
    height: 743px;
    z-index: 5;
    position: relative;
}

.banner-background {
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 743px;
}

.apply-navbar {
    width: 450px;
    display: flex;
    justify-content: center;
    text-align: center;

    a {
        width: 150px;
        color: $monthly-primary;
        font-weight: 600;
        font-size: 16px;
        text-decoration: none;
        cursor: pointer;

        svg {
            padding-right: 10px;
        }
    }
}

.marketing-navbar {
    padding-top: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;

    img {
        width: 140px;
        height: 33px;
        position: relative;

        &:hover {
            cursor: pointer;
        }
    }
}

.disabled-referral-link {
    pointer-events: none;
}

.apply-signout {
    color: tomato;
    font-weight: 500;
    font-size: 16px;
    border-color: transparent;
    outline: 0;
    background-color: transparent;

    :hover {
        cursor: pointer;
    }

    svg {
        padding-right: 10px;
    }
}

.banner-container {
    position: relative;
    z-index: 1;
}

.banner-section {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    padding-top: 170px;

}

.left-banner {
    width: 525px;
    text-align: left;

    h2 {
        color: $white;
        font-weight: 600;
        font-size: 65px;
        margin-bottom: 12px;

        span {
            color: $monthly-green;
            font-weight: 600;
            font-size: 65px;
        }
    }

    p {
        color: $white;
        font-size: 20px;
        font-weight: 400;
        width: 382px;
    }
}

.barcode-section {
    h6 {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-top: 30px;
        color: $white
    }

    img {
        width: 115px;
        height: 115px !important;
        position: relative;
        margin-top: 19px;
    }
}

.banner-download-icons {
    display: flex;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    margin: auto;
    padding-top: 30px;

    div {
        width: 140px;
        height: 55px;
        position: relative;

        img {
            width: 140px;

        }
    }

}

.right-banner {
    width: 436px;

    input {
        width: 436px;
        height: 71px;
        background-color: $white;
        border-color: transparent;
        box-sizing: border-box;
        padding-left: 30px;
        border-radius: 6px;
        margin-bottom: 25px;

    }

    button {
        color: white;
        background-color: $monthly-green;
        width: 190px;
        height: 60px;
        border-radius: 6px;
        border-color: transparent;
        letter-spacing: 0.1em;
        font-weight: 500;
    }
}


.calculator-wrapper {
    width: 100%;
    background-image: url('../assets/img/hero-banner2.png');
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 945px;
    z-index: 4;
    margin-top: -269px;
    display: flex;
    align-items: center;
}

.new-calculator-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 5;

}

.left-calculator {
    width: 505px;

    h4 {
        color: $white-100;
        font-size: 50px;
        width: 505px;

    }

    p {
        font-size: 20px;
        font-weight: 400;
        color: $white-100;
        width: 307px;
    }
}

.right-calculator {
    width: 610px;
    height: 670px;
    position: relative;
    background: none;
    border-radius: 32px;
    // $border: 5px;
    // background-clip: padding-box; /* !importanté */
    // border: solid $border transparent; /* !importanté */

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0; right: 0; bottom: 0; left: 0;
    //   z-index: -1;
    //   margin: -$border; /* !importanté */
    //   border-radius: inherit; /* !importanté */
    //   background: linear-gradient(to right, #00C598, #fff);
    // }
}

.border-gradient {
    border: 3px solid;
    border-image-slice: 1;
    border-width: 3px;
    border-image-source: linear-gradient(to right, #00C598, #fff);

}

.new-calculator {
    background: rgba(79, 86, 101, 0.2);
    backdrop-filter: blur(12px);
    width: 610px;
    height: 670px;
    border-radius: 32px;
    box-sizing: border-box;
    padding: 66px 84px;
    border: 3px solid $monthly-green;


}

.new-calc-form {
    margin: auto;
}

.calc-result {
    text-align: center;
    padding-top: 11px;

    p {
        margin-bottom: 12px;
        color: $white;
        font-size: 15px;
    }

    h2 {
        color: $white;
        font-size: 40px;
        font-weight: 600;

    }

}

.calc-formgroup {
    width: 100%;
    margin-bottom: 42px;

    label {
        text-transform: uppercase;
        color: $white;
        letter-spacing: 2px;
        margin-bottom: 10px;
        font-size: 15px;
    }

    input {
        width: 100%;
        height: 79px;
        border: 1px solid rgba(23, 0, 74, 0.1);
        border-radius: 10px;
        outline: 0;
        box-sizing: border-box;
        padding: 15px;
    }

    select {
        width: 100%;
        height: 79px;
        border: 1px solid rgba(23, 0, 74, 0.1);
        border-radius: 10px;
        outline: 0;
        box-sizing: border-box;
        padding: 15px;
    }
}

// how section

.why-card {
    width: 295px;
    height: 395px;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 35px;
    padding-right: 45px;
    padding-top: 41px;

    .why-icon {
        height: 70px;
    }

    h5 {
        color: $monthly-primary;
        height: 99px;
        margin-top: 25px;
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 29px;
        font-weight: 500;
    }

    p {
        font-size: 15px;
        line-height: 23px;
        font-weight: 400;
    }
}

.card-one {
    background-color: rgba(23, 0, 74, 0.1);

    h5 {
        width: 190px;
    }
}

.card-two {
    background-color: rgba(0, 197, 152, 0.1);

    h5 {
        width: 150px;
    }
}

.card-three {
    background-color: rgba(120, 139, 255, 0.1);

    h5 {
        width: 150px;
    }
}

.card-four {
    background-color: rgba(202, 235, 255, 0.2);

    h5 {
        width: 100px;
    }
}

// Marketing footer section 
.new-footer-wrapper {
    padding-top: 100px;
    padding-bottom: 83px;

    hr {
        background-color: $monthly-green;
    }
}

.new-flex-footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
}

.new-footer-logo {
    width: 150px;
    height: 36px;
}

.each-footer-section {
    width: 180px;

    div {
        padding-top: 28px;
    }

    h4 {
        color: $monthly-green;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
    }

    a {
        color: $monthly-dark-ash;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
    }
}

.footer-icon-section {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        font-size: 25px;
        color: $monthly-green;
    }
}

.sub-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 42px;

    div {
        font-size: 16px;
        font-weight: 500;
    }

    span {
        font-size: 18px;
        font-weight: 400;
    }
}

// Success Page 
.success-wrapper {
    width: 100%;
    height: 100%;
    z-index: 5;
    position: relative;
}

.success-bg-img {
    position: fixed;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.success-body {
    text-align: center;
    top: 120px;
    padding-bottom: 70px;
    z-index: 8;
    position: relative;
}

.monthly-mobile-image {
    width: 250px;
    height: 500px;
    margin-top: 77px;
    margin-bottom: 77px;
}

.monthly-agent-gift {
    width: 300px;
    height: 300px;
    margin-top: 77px;
    margin-bottom: 77px;
}

.scan-title {
    color: $monthly-primary;
    font-size: 45px;
    font-weight: 600;
    margin: auto;
    margin-bottom: 77px;
    width: 753px;

    span {
        color: $monthly-green;
    }
}

.search-title {
    color: $monthly-primary;
    font-size: 40px;
    font-weight: 600;
    margin: auto;
    margin-bottom: 18px;
    text-align: center;

    span {
        color: $monthly-green;
    }
}

.success-qr {
    width: 200px;
    height: 200px;
}

.agent-success-referral-code {
    color: $monthly-primary;
    margin-bottom: 40px;

    p {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    h2 {
        font-size: 40px;
        font-weight: 700;
    }

}

.agent-referral-link-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}

.agent-referral-link {
    width: 324px;

    p {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
    }

    button {
        width: 100%;
        height: 60px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 20px;
        background-color: $monthly-green;
        border-color: transparent;
        color: white;
        justify-content: center;

    }
}

.small-success-title {
    color: $monthly-primary;
    font-size: 25px;
    font-weight: 600;

    span {
        color: $monthly-green;
    }
}

.success-alert {
    background-color: rgba(0, 197, 152, 1);
    width: 274px;
    margin: auto;
    height: 45px;
    border-radius: 20px;
    border: 1px solid $monthly-green;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;

    h6 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: black;
        font-size: 15px;
    }

    span {
        color: $monthly-green;

    }

    img {
        width: 20px;
        height: 20px;
    }

}

.download-icons {
    display: flex;
    width: 300px;
    margin: auto;
    justify-content: space-between;
    padding-top: 30px;

    div {
        width: 141px;

        img {
            width: 141px;
        }
    }
}



@media(min-width: 1336px) {
    .banner-container {
        width: 1240px;
        margin: auto;
    }

    .new-calculator-section {
        width: 1240px;
        margin: auto;
    }

    .page-container {
        width: 1240px;
        margin: auto;
        position: relative;
    }


}

@media(max-width: 1336px) {
    .banner-container {
        width: 90%;
        margin: auto;
    }

    .new-calculator-section {
        width: 90%;
        margin: auto;
    }

    .page-container {
        width: 90%;
        margin: auto;
        position: relative;
    }

    .new-flex-footer {
        flex-wrap: wrap;

    }
}

@media(max-width: 1240px) {
    .left-banner {
        width: 450px;

        h2 {
            font-size: 52px;

            span {
                font-size: 52px
            }
        }
    }

    .right-banner {
        width: 450px;
    }

    .left-calculator {
        width: 450px;
        margin: auto;

        h4 {
            width: 450px;
        }
    }

    .right-calculator {
        width: 450px;
        height: 480px;
        margin: auto;
    }

    .new-calculator {
        width: 100%;
        height: 100%;
        padding: 28px 34px;
    }

    .calc-formgroup {
        margin-bottom: 30px;

        input {
            height: 45px;
        }

        select {
            height: 45px;
            padding: 5px 15px;
        }
    }



}

@media(max-width: 991px) {
    .banner-wrapper {
        height: 843px;
    }

    .banner-background {
        height: 843px;
    }

    .banner-section {
        flex-direction: column;
        padding-top: 180px;
        gap: 67px;
    }

    .left-banner {
        width: 80%;

        h2 {
            font-size: 65px;

            span {
                font-size: 65px;
            }
        }
    }

    .right-banner {
        display: none
    }

    .large-get-started-btn {
        display: none;
    }

    .new-calculator-section {
        flex-direction: column;
        padding-top: 250px;
        gap: 30px;
    }

    .calculator-wrapper {
        margin-top: -200px;
    }

    .left-calculator {
        width: 80%;

        h4 {
            width: 80%;
        }
    }

    .right-calculator {
        width: 80%;
    }

    .scan-title {
        display: none;
    }

    .success-qr {
        display: none;
    }

    .marketing-navbar {
        width: 100%;
        margin: auto;
    }

    .apply-navbar {
        font-size: 14px;

    }

    .apply-signout {
        font-size: 16px;
    }

    .search-title {
        display: none;
    }

    .barcode-section {
        display: none;
    }
}

@media(min-width:991px) {
    .banner-download-icons {
        display: none;
    }

    .small-success-title {
        display: none;
    }

    .large-get-started-btn {
        color: white;
        background-color: $monthly-green;
        width: 190px;
        height: 60px;
        border-radius: 6px;
        border-color: transparent;
        letter-spacing: 0.1em;
        font-weight: 500;
        margin-top: 69px;
    }

    .agent-download-icons {
        display: none;
    }

    .banner-download-icons {
        height: 55px;
    }

}

@media(max-width: 768px) {
    .banner-section {
        padding-top: 142px;
    }

    .banner-container {
        width: 90%;
    }

    .success-qr {
        display: none;
    }

    .search-title {
        display: none;
    }

    .agent-download-icons {
        display: flex;
        width: 300px;
        margin: auto;
        justify-content: space-between;
        padding-top: 30px;

        div {
            width: 141px;

            img {
                width: 141px;
            }
        }
    }

    .agent-referral-link-container {
        flex-direction: column;
        gap: 40px;
    }

    .agent-referral-link {
        margin: auto;
    }

    .marketing-navbar {
        img {
            width: 100px !important;
            height: 26px !important;
        }
    }

    .apply-navbar {
        width: 250px;
        font-size: 12px;
        font-weight: 500;

        svg {
            padding-right: 5px;
        }

        a {
            width: 100px;
        }
    }

    .apply-signout {
        font-size: 14px;
    }

}

@media(max-width: 576px) {
    .calculator-wrapper {
        background-image: url('../assets/img/small-hero-banner2.png');
        height: 880px;
    }

    .banner-wrapper {
        height: 529px;
        z-index: 8;
    }

    .banner-background {
        height: 529px;
    }

    .banner-section {
        padding-top: 120px;
        ;
    }

    .banner-container {
        width: 85%;
        margin: auto;
    }

    .marketing-navbar {
        width: 100%;
        margin: auto;
    }


    .disabled-referral-link {
        display: none;
    }

    .success-body {
        top: 100px;
    }

    .left-banner {
        width: 100%;

        h2 {
            font-size: 30px;
            margin-bottom: 19px;
            line-height: 39px;

            span {
                font-size: 30px;
            }
        }

        p {
            font-size: 15px;
            font-weight: 400;
            width: 80%;
            line-height: 25px;
        }

    }

    .right-banner {
        width: 100%;

        button {
            height: 50px;
        }

        input {
            width: 95%;
        }
    }

    .left-calculator {
        width: 100%;

        h4 {
            font-size: 30px;
            width: 100%;
        }

        p {
            font-size: 15px;
            font-weight: 400;
            width: 80%;
        }

    }

    .right-calculator {
        width: 100%;
    }

    .new-calculator {
        padding: 19px 38px;
    }

    .calc-formgroup {
        label {
            font-size: 12px;
            letter-spacing: 0.8px;
        }

        input {
            height: 45px;
        }
    }

    .each-footer-section {
        width: 150px;

        h4 {
            font-size: 18px;
        }

        a {
            font-size: 15px;
        }

        img {
            width: 148px;
        }

    }

    .sub-footer {
        flex-wrap: wrap;
        gap: 25px;
        padding-top: 27px;
    }

    .scan-title {
        display: none;
    }

    .search-title {
        display: none;
    }

    .monthly-mobile-image {
        width: 200px;
        height: 400px;
        margin-top: 52px;
        margin-bottom: 52px;
    }

    .monthly-agent-gift {
        width: 180px;
        height: 180px;
        margin-top: 52px;
        margin-bottom: 52px;
    }

    .apply-navbar {
        width: 100px;
    }

}

@media(max-width: 426px) {
    .page-container {
        width: 90%;
        margin: auto;
    }


}